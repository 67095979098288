
/*Overwrites required due whatsapp vue library */
h1 { background: none !important;}

a:link,
a:visited {
  color: #30abc6 !important;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

a.btn-primary, a.btn-info {

  color: black;
  &:link,
  &:active,
  &:visited {
    color: black !important;
  }
}

a.btn-info.active {
    border-bottom: 1px solid #30abc6 ;
}

small {
  color: #ffffff;
}

p {
  font-weight: 500;
}

.vue-simple-drawer {
  background-color: transparent !important;
}

.nav-item {
  a {
    font-weight: normal;
  }
}

a {
  font-weight: bold;
}

$swal2-background: #990000;

.swal2-popup {
  background: radial-gradient(#282626, #0a0a0a) !important;
}
.swal2-html-container {
  overflow: hidden !important;
}

.v-popper__inner {
  background-color: #2b2a2a;
}

.v-popper--theme-dropdown .v-popper__inner {
  padding: 5px;
  background-color: #2b2a2a !important;
}

.fr-bordered {
  border: 1px solid #30abc6;
}


