.carrousel-images {
  margin-left: 60px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.carrousel-image {
  border-radius: 4px !important;
  margin: 4px;

  &.active {
    border: 2px solid #9eb496;
  }
  &:hover {
    border: 3px solid #9eb496;
  }
}

@media only screen and (max-width: 600px) {
  .carrousel-image {
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0px 9px 2px 11px #161111;
    border: 1px solid #9eb496;
  }
  .carrousel-images {
    margin-left: 0;
  }
}

