 // Core

@import "dashboard/custom/functions";
@import "dashboard/bootstrap/functions";

@import "dashboard/custom/variables";
@import "dashboard/bootstrap/variables";

@import "dashboard/custom/mixins";
@import "dashboard/bootstrap/mixins";

 // Bootstrap components

@import "dashboard/bootstrap/root";
@import "dashboard/bootstrap/reboot";
@import "dashboard/bootstrap/type";
@import "dashboard/bootstrap/images";
//@import "dashboard/bootstrap/code";
@import "dashboard/bootstrap/grid";
@import "dashboard/bootstrap/tables";
//@import "dashboard/bootstrap/forms";
@import "dashboard/bootstrap/buttons";
@import "dashboard/bootstrap/transitions";
//@import "dashboard/bootstrap/dropdown";
@import "dashboard/bootstrap/button-group";
@import "dashboard/bootstrap/input-group";
//@import "dashboard/bootstrap/custom-forms";
@import "dashboard/bootstrap/nav";
@import "dashboard/bootstrap/navbar";
@import "dashboard/bootstrap/card";
//@import "dashboard/bootstrap/breadcrumb";
@import "dashboard/bootstrap/pagination";
@import "dashboard/bootstrap/badge";
//@import "dashboard/bootstrap/jumbotron";
@import "dashboard/bootstrap/alert";
//@import "dashboard/bootstrap/progress";
@import "dashboard/bootstrap/media";
//@import "dashboard/bootstrap/list-group";
//@import "dashboard/bootstrap/close";
@import "dashboard/bootstrap/modal";
@import "dashboard/bootstrap/tooltip";
//@import "dashboard/bootstrap/popover";
//@import "dashboard/bootstrap/carousel";
@import "dashboard/bootstrap/utilities";
//@import "dashboard/bootstrap/print";

 // Custom components

 @import "dashboard/custom/reboot.scss";
 @import "dashboard/custom/global.scss";
 @import "dashboard/custom/utilities.scss";
 //@import "dashboard/custom/alerts.scss";
 //@import "dashboard/custom/avatars.scss";
 @import "dashboard/custom/badge.scss";
 @import "dashboard/custom/buttons.scss";
 //@import "dashboard/custom/close.scss";
 //@import "dashboard/custom/custom-forms.scss";
 //@import "dashboard/custom/dropdown.scss";
 @import "dashboard/custom/footer.scss";
 //@import "dashboard/custom/forms.scss";
 @import "dashboard/custom/grid.scss";
 @import "dashboard/custom/icons.scss";
 @import "dashboard/custom/images.scss";
 //@import "dashboard/custom/input-group.scss";
 //@import "dashboard/custom/info-areas.scss";
 //@import "dashboard/custom/modal.scss";
 @import "dashboard/custom/nav.scss";
 @import "dashboard/custom/navbar.scss";
 //@import "dashboard/custom/pagination.scss";
 @import "dashboard/custom/tabs.scss";
 //@import "dashboard/custom/pills.scss";
 //@import "dashboard/custom/popover.scss";
 //@import "dashboard/custom/progress.scss";
 @import "dashboard/custom/type.scss";
 @import "dashboard/custom/tables";
 //@import "dashboard/custom/checkboxes-radio";
 //@import "dashboard/custom/fixed-plugin";
 @import "dashboard/custom/sidebar-and-main-panel.scss";
 @import "dashboard/custom/misc.scss";
 //@import "dashboard/custom/rtl.scss";
 @import "dashboard/custom/timeline.scss";

 // Vendor / Plugins

//@import "dashboard/custom/vendor/plugin-datetimepicker.scss";
//@import "dashboard/custom/vendor/plugin-nouislider.scss";
//@import "dashboard/custom/vendor/plugin-fullcalendar.scss";
@import "dashboard/custom/vendor/plugin-sweetalert2.scss";
//@import "dashboard/custom/vendor/plugin-bootstrap-switch.scss";
//@import "dashboard/custom/vendor/plugin-bootstrap-select.scss";
//@import "dashboard/custom/vendor/plugin-jasny-fileupload.scss";
//@import "dashboard/custom/vendor/plugin-tagsinput.scss";
@import "dashboard/custom/vendor/plugin-perfect-scrollbar.scss";
//@import "dashboard/custom/vendor/plugin-jquery.jvectormap.scss";
//@import "dashboard/custom/vendor/plugin-datatables.net.scss";
@import "dashboard/custom/vendor/plugin-animate-bootstrap-notify.scss";
//@import "dashboard/custom/vendor/plugin-card-wizard.scss";

// Vendor Element UI plugins
//@import "dashboard/custom/vendor/element-ui/plugin-tags";
//@import "dashboard/custom/vendor/element-ui/plugin-slider";
//@import "dashboard/custom/vendor/element-ui/plugin-select";
//@import "dashboard/custom/vendor/element-ui/plugin-inputs";
//@import "dashboard/custom/vendor/element-ui/plugin-tables";
//@import "dashboard/custom/vendor/element-ui/plugin-datepicker";

// example pages and sections

//@import "dashboard/custom/example-pages.scss";

// light mode

//@import "dashboard/custom/white-content.scss";

// Cards

@import 'dashboard/custom/card';
//@import "dashboard/custom/cards/card-chart";
//@import "dashboard/custom/cards/card-map";
//@import "dashboard/custom/cards/card-user";
//@import "dashboard/custom/cards/card-task";
@import "dashboard/custom/cards/card-animation-on-hover";
@import "dashboard/custom/cards/card-collapse";
//@import "dashboard/custom/cards/card-contributions";
//@import "dashboard/custom/cards/card-lock";
//@import "dashboard/custom/cards/card-pricing";
//@import "dashboard/custom/cards/card-profile";
//@import "dashboard/custom/cards/card-plain";
//@import "dashboard/custom/cards/card-register";
//@import "dashboard/custom/cards/card-stats";
//@import "dashboard/custom/cards/card-subcategories";
//@import "dashboard/custom/cards/card-testimonials";
//@import "dashboard/custom/cards/card-wizard";
 @import "dashboard/custom/carrousel";

.directions-map {
  filter: grayscale(100%);
  border: 5px solid black;
}

.Cookie--black {
  background-color: black;
  padding: 15px;
  font-weight: bold;
  border-top: 1px solid #3485b0;
}

 .select {
   font-size: 1rem;
   line-height: 1.1;
   background-color: #fff;
   background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
 }

 .text-smart {
   text-align: left;
 }

 @media only screen and (max-width: 600px) {
   .text-smart {
     text-align: center;
   }
 }

 .detail-table-name {
   text-transform: uppercase !important;
   font-size: 11px;
   font-weight: bolder;
 }

 .detail-table-value {
   text-transform: capitalize !important;
   font-size: 12px;
   margin-bottom: 10px;
 }

 .nav-tabs {
   display: flex;
   border-bottom: 0.5px solid gray;
   li:active, a:active, li.active {
     border-bottom: 1px solid #3485b0;
   }
   .nav-item {
     a {
       cursor: pointer;
     }
     li:active, a:active {
       background-color: #3485b0;
       font-weight: bold;
     }
   }
 }

 .footer {
   ul {
     padding-block-start: 0px;
     padding-inline-start: 0px;
   }
 }

 .fade-enter-active {
   transition: opacity 1s ease-in-out;
 }

 .fade-enter-to {
   opacity: 1;
 }

 .fade-enter {
   opacity: 0;
 }
 .centered {
   margin-left: auto;
   margin-right: auto;
   left: 0;
   right: 0;
 }

 .section-header {
   margin-top: 1px;
   width: 100%;
   font-weight: bold;
   padding: 8px;
   background-color: #262424;
 }

 @keyframes placeHolderShimmer{
   0%{
     background-position: -468px 0
   }
   100%{
     background-position: 468px 0
   }
 }

 .loading-text {
   animation-duration: 1.25s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: #262424;
   background: linear-gradient(to right, #1e3434, #262424);
   background-size: 800px 104px;
   min-height: 300px;
   position: relative;
 }

 .large-loading-text {
   @extend .loading-text;
   min-height: 800px;
 }


 .tim-row {
   margin-bottom: 20px;
 }

 .tim-white-buttons {
   background-color: #777777;
 }

 .typography-line {
   padding-left: 25%;
   margin-bottom: 35px;
   position: relative;
   display: block;
   width: 100%;
 }

 .typography-line span {
   color: #c0c1c2;
   display: block;
   font-weight: 400;
   font-size: 13px;
   line-height: 13px;
   left: 0;
   position: absolute;
   width: 260px;
   text-transform: none;
 }

 .tim-row {
   padding-top: 60px;
 }

 .tim-row h3 {
   margin-top: 0;
 }

 .offline-doc .page-header:before {
   content: "";
   background: rgba(0, 0, 0, .75);
 }

 .offline-doc .page-header {
   display: flex;
   align-items: center;
 }

 .offline-doc .footer {
   position: absolute;
   width: 100%;
   background: transparent;
   bottom: 0;
   color: #fff;
   z-index: 1;
 }

 #map {
   position: relative;
   width: 100%;
   height: 100vh;
 }

 .tulum-party-title {
   font-family: $font-family-alt;
   font-style: normal;
   font-weight: bold;
 }

 .tulum-party-subtitle {
   font-family: $font-family-base;
   color: #efefef !important;
   line-height: 140%;
   font-style: normal;
   font-weight: 900;
 }

 header {
   background-color: rgba(33, 33, 33, 0.9);
   color: #ffffff;
   display: block;
   font: 14px/1.3 Arial, sans-serif;
   height: 50px;
   position: relative;
   z-index: 5;
 }

 header a, a:visited {
   text-decoration: none;
   color: #C5FBEE;
 }

 .transparent-card {
   background-color: transparent;
 }

 .palm-header {
   background-image: url( "https://imgix.cosmicjs.com/a2f84220-877e-11eb-8ee1-4b2192b64ce4-stars.png");
   background-repeat: repeat;
   background-size: contain;
   background-position: center;
 }



 li {
   margin: 10px;
 }

 .brand-title {
   color: black !important;
   font-size: 1.1rem;
   background-image: linear-gradient(to bottom, #30abc6, #050404)
 }

 .brand-title-black {
   color: #30abc6 !important;
   font-size: 1.1rem;
   background-color: transparent;
 }

 img[lazy=loading] {
   opacity: 0.2;
 }

 img[lazy] {
   opacity: 1;
   transition: opacity 1.7s;
 }

 .fade-high-light-enter-active,
 .fade-high-light-active {
   background: #30abc6;
   transition: opacity 1.5s ease;
 }
 .fade-high-light-from,
 .fade-high-light-to {
   opacity: 0.5;
   background: silver;
 }

 .btn-call-for-action {
   font-family: "Montserrat", sans-serif;
   text-transform: uppercase;
   color: #FFFFFF !important;
   border: 1px solid #30abc6;
 }


 td {
   font-size: 0.7rem !important;
 }

 tr {
   font-size: 0.7rem !important;
 }

 .badge-category {
   margin: 2px;
   font-size: 0.7rem;
   color: silver;
   background-color: #1c1c1c !important;
   padding: 1px;
   font-weight: lighter;

 }

 .post-wrapper {
   position: relative;
   padding-bottom: 56.25%; /* 16:9 */
   padding-top: 25px;
   height: 0;
   background-image: linear-gradient(0deg, transparent, black 75%);
 }

 .post-wrapper iframe {
   /*position: absolute;*/
   /*top: 0;*/
   /*left: 0;*/
   width: 100%;
   height: 1200px;
 }

 .image-on-show {
   animation: zoominoutsinglefeatured 2s;
 }

 @keyframes zoominoutsinglefeatured {
   0% {
     opacity: 0.1;
     transform: scale(1, 0.5);
   }
   50% {
     opacity: 0.4;
     transform: scale(1, 0.7);
   }

   70% {
     opacity: 0.6;
     transform: scale(1, 1);
   }
   100% {
     opacity: 1;

   }
 }

 @keyframes gradient-anim {
   0% {
     background-position: 0% 50%;
   }
   50% {
     background-position: 100% 50%;
   }
   100% {
     background-position: 0% 50%;
   }
 }
 @keyframes gradient-anim-2 {
   0% {
     background: linear-gradient(0deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
   }

   100% {
     background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
   }
 }

 .gradient {
   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
   /*animation: gradient-anim 15s ease infinite;*/
   color: black;
 }

 .gradient2 {
   /*animation:  gradient-anim-2  2s ease infinite;;*/
   background: linear-gradient( 0deg, black, #23d5ab, #644BA6, #A64B60);
   color: black;
 }

 .lds-dual-ring {
   display: inline-block;
   width: 80px;
   height: 80px;
 }
 .lds-dual-ring:after {
   content: " ";
   display: block;
   width: 44px;
   height: 44px;
   margin: 8px;
   border-radius: 50%;
   border: 6px solid #4b9b78;
   border-color: #4b9b78 transparent #319a80 transparent;
   animation: lds-dual-ring 1.2s linear infinite;
 }
 @keyframes lds-dual-ring {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }


 .tulum-party-subtitle-bg {
   background-color: #373737;
   color: #acacac !important;
   font-weight: bold;;
 }

 dl {
   color: #efefef;
 }
 dt {
   font-weight: bold;
   margin-bottom: 5px;
 }

 dd {
   margin-bottom: 7px;
 }

 .faq-content {
   background-color: #323232;
 }
 .diego-hero{
  background-color:rgba(24, 166, 246, 0.4);
  border-radius: 7px;
}
.reservation-inputs {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%; /* Ajuste dinámico al 100% del contenedor padre */
  height: 50px;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
}
 .our-services{
   background-color: rgb(33, 32, 32);
   padding: 10px;
   text-align: left;
   margin: 0%;
 }





