.table{

  > tbody > tr > td {
    color: rgba(255, 255, 255, 0.7) !important;
  }

    .img-wrapper{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
    }

    .img-row{
      max-width: 60px;
      width: 60px;
    }

    .btn{
        margin: 0;
    }

    small,.small{
      font-weight: 300;
    }

   > thead > tr > th{
       border-bottom-width: 1px;
       font-size: 12px;
       text-transform: uppercase;
       font-weight: $font-weight-extra-bold;
       border: 0;
       color: rgba($white, 0.7);
   }

   .radio,
   .checkbox{
       margin-top: 0;
       margin-bottom: 0;
       padding: 0;
       width: 15px;

       .icons{
           position: relative;
       }

        label{
            &:after,
            &:before{
                top: -17px;
                left: -3px;
            }
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       border-color: rgba(255, 255, 255, 0.1);
       padding: 12px 7px;
       vertical-align: middle;
   }

   .th-description{
       max-width: 150px;
   }

   .td-total{
        font-weight: $font-weight-bold;
        font-size: $h5-font-size;
        padding-top: 20px;
        text-align: right;
    }

   .td-actions .btn{
    margin: 0px;
    }

    > tbody > tr{
        position: relative;
    }

    > tfoot > tr {
      color: hsla(0,0%,100%,.7);
      text-transform: uppercase;
    }

}

.table-responsive{
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive{
    margin-bottom: 30px;
}

// datatables
.dataTables_wrapper {
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }

  .form-control-sm {
    font-size: 10px;
  }
}
