h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: $headings-line-height;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-base;
}

.card {
  h1, h2, h3, h4, h5, h6, p {
    color: $opacity-8;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-normal;
  color: $opacity-8;
}

a {
  color: $primary-states;
  font-weight: $font-weight-light;

  &:hover,
  &:focus {
    color: $primary;
  }
}

h1, .h1 {
  line-height: 1.05;
  margin-bottom: $margin-base-vertical * 2;
  font-weight: 900;
  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: .8;
  }

}

h2, .h2 {
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.2;
  font-weight: 900;
}

h3, .h3 {
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
  text-transform: uppercase;
}

h4, .h4 {
  line-height: 1.45em;
  margin-bottom: $margin-base-vertical;
  text-transform: uppercase;

  font-weight: bolder;

  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}

h5, .h5 {
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6, .h6 {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

p {
  color: #efefef !important;
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  &.description {
    font-size: 1.14em;
  }
}


.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black;
      text-decoration: none;
    }
  }

  & + .category {
    margin-top: -10px;
  }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
  color: #C5FBEE;
  font-weight: $font-weight-light;
}

.category,
.card-category {
  text-transform: uppercase;
  font-weight: 900;
  color: #F0FDA7 !important;
  font-size: $font-size-sm;
}

.card-category {
  font-size: $font-size-sm;
}

.blockquote {
  border-left: none;
  border: 1px solid $default;
  padding: 20px;
  font-size: $blockquote-font-size;
  line-height: 1.8;

  small {
    color: #C5FBEE;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }


}

ul li, ol li {
  color: $white;
}

pre {
  color: $opacity-8;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: $spacer;
  margin-bottom: $spacer;
}
