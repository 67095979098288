
.card-animation-on-hover{
  cursor: pointer;
  transition: all .3s cubic-bezier(.34,1.61,.7,1);
  &:hover{
    transform: translate3d(0,-5px,0);
    background-color: #30abc6 !important;
  }
}

.card-animation-on-hover-no-bg {
  transition: all .3s cubic-bezier(.34,1.61,.7,1);
  &:hover{
    transform: translate3d(0,-5px,0);
    border: solid 1px #30abc6 ;
  }
}
