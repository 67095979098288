.card{
    background: $card-black-background;
    border: 0;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: $box-shadow;


    label{
      color: rgba($white, 0.6);
    }

    .card-title {
      margin-bottom: .75rem;
    }

    .card-body{
      padding: 15px;

      &.table-full-width{
        padding-left: 0;
        padding-right: 0;
      }

      .card-title{
        color: $white;
        text-transform: inherit;
        font-weight: $font-weight-light;
        margin-bottom: .75rem;
      }

      .card-description, .card-category{
        color: rgba($white, 0.6);
      }

    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;
      color: rgba($white,0.8);

      .card-title{
          color: $white;
          font-weight: 100;
      }

      .card-category{
        color: $dark-gray;
        margin-bottom: 5px;
        font-weight: 300;
      }
    }

    &.card-plain {
      background: transparent;
      box-shadow: none;
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    label{
        font-size: $font-size-sm;
        margin-bottom: 5px;

    }

    .card-footer{
        background-color: transparent;
        border: 0;
        padding: 15px;

        h6{
          margin-bottom: 0;
          padding: 7px 0;
        }
    }
}

.card-body{
  padding: $card-spacer-y;
}
